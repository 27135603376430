import React from "react"
import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import Felicidades from '../components/pages/Felicidades'


const FelicidadesPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <Felicidades companyName={pageContext.company} pageContext={pageContext}/>
      </>
    )}
  />
)

export default FelicidadesPage